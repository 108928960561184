import React from "react"
import { expenseAndIncomeManagementPage } from "../../Content/FeatureInnerPages"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import FeatureDetailsPage from "../../components/pages/FeatureDetails/FeatureDetailsPage"

const ExpenseAndIncomeManagement = () => {
  return (
    <Layout>
      <Seo
        title="Streamline Expense Tracking & Cash Flow with Synkli"
        description={`Discover Synkli, the accountant-friendly software for expense tracking, cash flow management, and actionable insights to enhance your financial workflow.`}
      />

      <FeatureDetailsPage data={expenseAndIncomeManagementPage} />
      
    </Layout>
  )
}

export default ExpenseAndIncomeManagement
